(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

(function (ElementProto) {
  if (typeof ElementProto.matches !== "function") {
    ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
      var element = this;
      var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
      var index = 0;
      while (elements[index] && elements[index] !== element) {
        ++index;
      }
      return Boolean(elements[index]);
    };
  }
  if (typeof ElementProto.closest !== "function") {
    ElementProto.closest = function closest(selector) {
      var element = this;
      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }
        element = element.parentNode;
      }
      return null;
    };
  }
})(window.Element.prototype);

},{}],2:[function(require,module,exports){
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/features/object/assign.js");
require("core-js/features/array/find.js");
require("core-js/features/array/find-index.js");
require("core-js/features/array/fill.js");
require("core-js/features/array/includes.js");
require("core-js/features/array/from.js");
require("core-js/features/promise/index.js");
require("core-js/features/promise/finally.js");
require("core-js/web/url");
require("core-js/features/map/index.js");
require("core-js/features/set/index.js");
require("./closest.js");
require("./popstate-event.js");
require("whatwg-fetch");
var _smoothscrollPolyfill = _interopRequireDefault(require("smoothscroll-polyfill"));
// import "core-js/fn/promise/index.js";
// import "core-js/fn/promise/finally.js";
// import "core-js/fn/object/assign.js";
// import "core-js/fn/array/find.js";
// import "core-js/fn/array/find-index.js";
// import "core-js/fn/array/fill.js";
// import "core-js/fn/string/starts-with.js";
// import "core-js/fn/array/includes.js";
// import "core-js/fn/array/from.js";

// for react 16
// https://facebook.github.io/react/blog/2017/09/26/react-v16.0.html#javascript-environment-requirements
// import "core-js/es6/map.js";
// import "core-js/es6/set.js";

// import svg4everybody from "svg4everybody";

// svg4everybody();

// window.fetch

var isSmoothScrollSupported = ("scrollBehavior" in document.documentElement.style);
if (!isSmoothScrollSupported) {
  _smoothscrollPolyfill.default.polyfill();
}

},{"./closest.js":1,"./popstate-event.js":3,"@babel/runtime/helpers/interopRequireDefault":4,"core-js/features/array/fill.js":"core-js/features/array/fill.js","core-js/features/array/find-index.js":"core-js/features/array/find-index.js","core-js/features/array/find.js":"core-js/features/array/find.js","core-js/features/array/from.js":"core-js/features/array/from.js","core-js/features/array/includes.js":"core-js/features/array/includes.js","core-js/features/map/index.js":"core-js/features/map/index.js","core-js/features/object/assign.js":"core-js/features/object/assign.js","core-js/features/promise/finally.js":"core-js/features/promise/finally.js","core-js/features/promise/index.js":"core-js/features/promise/index.js","core-js/features/set/index.js":"core-js/features/set/index.js","core-js/web/url":"core-js/web/url","smoothscroll-polyfill":"smoothscroll-polyfill","whatwg-fetch":"whatwg-fetch"}],3:[function(require,module,exports){
"use strict";

// IE11 does not support using Event constructors (new Event())
// If we cannot use the constructor, we'll use a re-implementation of PopStateEvent
// instead of the native one.

// This was derived from the CustomEvent polyfill on MDN: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent

(function () {
  function PopStateEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      state: undefined
    };
    var evt = document.createEvent("PopStateEvent");
    evt.initPopStateEvent(event, params.state, params.bubbles, params.cancelable);
    return evt;
  }
  PopStateEvent.prototype = window.PopStateEvent.prototype;
  try {
    new window.PopStateEvent("test");
  } catch (error) {
    window.PopStateEvent = PopStateEvent;
  }
})();

},{}],4:[function(require,module,exports){
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
module.exports = _interopRequireDefault, module.exports.__esModule = true, module.exports["default"] = module.exports;

},{}]},{},[2]);
